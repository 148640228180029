/* You can add global styles to this file, and also import other style files */
/* General Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: #f0f0f0; /* Light background for the body */
    color: #333; /* Default text color */
    line-height: 1.6; /* Line height for readability */
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.toast-success {
    background-color: #4caf50; /* Green background for success */
    color: white; /* White text */
  }
  
  .toast-error {
    background-color: #f44336; /* Red background for error */
    color: white; /* White text */
  }
  
  /* Ensure toast notifications are positioned correctly */
  .mat-snack-bar-container {
    position: fixed; /* Fix position */
    right: 20px; /* Align to the right */
    top: 80px; /* Adjust this value to place below your nav bar */
    transform: translateY(0); /* Initial position */
    transition: transform 0.3s ease; /* Animation for moving upwards */
  }
  
  /* Move the toast upwards before disappearing */
  .mat-snack-bar-container:not(.ng-enter) {
    transform: translateY(-20px); /* Move upwards */
  }
  
  /* Optional: Adjust z-index if needed */
  .mat-snack-bar-container {
    z-index: 10000; /* Ensure it's above other elements */
  }
  